// SearchableSelect.js
import React, { useState, useRef } from 'react';
import {
  Box,
  Typography,
  Chip,
  Popover,
  TextField,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Checkbox,
} from '@material-ui/core';

const SearchableSelect = ({ options, selected, onChange, label }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const anchorRef = useRef(null);

  const handleOpen = () => {
    setAnchorEl(anchorRef.current);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setSearchTerm('');
  };

  const open = Boolean(anchorEl);
  const id = open ? 'searchable-select-popover' : undefined;

  const filteredOptions = options.filter((option) =>
    option.name.toLowerCase().includes(searchTerm.toLowerCase())
  );
  const sortedOptions = [...filteredOptions].sort((a, b) => {
    const aSelected = selected.includes(a.id);
    const bSelected = selected.includes(b.id);
    if (aSelected && !bSelected) return -1;
    if (!aSelected && bSelected) return 1;
    return a.name.localeCompare(b.name);
  });

  const handleToggleOption = (event, optionId) => {
    event.stopPropagation();
    if (selected.includes(optionId)) {
      onChange(selected.filter((id) => id !== optionId));
    } else {
      onChange([...selected, optionId]);
    }
  };

  const renderDisplay = () => (
    <Box
      ref={anchorRef}
      onClick={handleOpen}
      style={{
        border: '1px solid #ccc',
        borderRadius: 4,
        padding: 4,
        minHeight: 80,
        display: 'flex',
        flexWrap: 'wrap',
        cursor: 'pointer',
      }}
    >
      {selected.length === 0 ? (
        <Typography variant="body2" color="textSecondary">
          {label}
        </Typography>
      ) : (
        selected.map((selId) => {
          const found = options.find((opt) => opt.id === selId);
          return found ? (
            <Chip key={selId} label={found.name} size="small" style={{ margin: 2 }} />
          ) : null;
        })
      )}
    </Box>
  );

  return (
    <>
      {renderDisplay()}
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        disableAutoFocus
        disableEnforceFocus
        disableRestoreFocus
      >
        <Box p={2} style={{ width: 300 }}>
          <TextField
            placeholder="Search..."
            variant="outlined"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            fullWidth
          />
          <List style={{ maxHeight: 200, overflowY: 'auto' }}>
            {sortedOptions.map((option) => (
              <ListItem
                key={option.id}
                button
                onClick={(e) => handleToggleOption(e, option.id)}
              >
                <ListItemIcon>
                  <Checkbox checked={selected.includes(option.id)} disableRipple />
                </ListItemIcon>
                <ListItemText primary={option.name} />
              </ListItem>
            ))}
          </List>
        </Box>
      </Popover>
    </>
  );
};

export default SearchableSelect;
